<template>
  <div>
    <advertisment-section v-if="!is_mobilesize" />
    <Countdown />
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <div class="d-flex justify-content-center col-12 text-center">
                <h2 class="mb-1">Scan</h2>
              </div>
              <div
                class="d-flex justify-content-center mb-1 col-12 text-center"
              >
                <h4>Paste solidity contract address below</h4>
              </div>
              <b-row>
                <b-col md="3">
                  <h3>Look Up Source Code:</h3>
                </b-col>
                <b-col md="7">
                  <b-form-group label-for="iv-token-name" :state="state">
                    <validation-provider
                      #default="{ errors }"
                      name="Look Up Source Code"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-form-input
                          v-model="contract_address"
                          id="iv-token-name"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter a Binance Smart Chain solidity contract address"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="2">
                  <b-col cols="12" class="text-center" v-if="is_logged_in">
                    <b-button
                      v-if="!loading"
                      variant="info"
                      type="submit"
                      loading="true"
                      @click="scanContract"
                    >
                      Lookup
                    </b-button>
                    <b-spinner
                      v-else
                      label="Loading..."
                      class="mr-2 mx-1"
                      style="width: 13px; height: 13px"
                    ></b-spinner>
                  </b-col>
                  <b-col cols="12" class="text-center" v-else>
                    <b-button
                      variant="info"
                      type="submit"
                      loading="true"
                      @click="linked('login')"
                    >
                      Please Log to Scan
                    </b-button>
                  </b-col>
                </b-col> -->
                <b-col md="2">
                  <b-col cols="12" class="text-center">
                    <b-button
                      v-if="!loading"
                      variant="info"
                      type="submit"
                      loading="true"
                      @click="scanContract"
                    >
                      Lookup
                    </b-button>
                    <b-spinner
                      v-else
                      label="Loading..."
                      class="mr-2 mx-1"
                      style="width: 13px; height: 13px"
                    ></b-spinner>
                  </b-col>
                </b-col>
              </b-row>
              <b-row> </b-row>
            </b-col>
          </b-row>

          <b-col v-if="loading" cols="12" class="text-center">
            <b-spinner class="mt-2" variant="primary" />
          </b-col>
        </b-form>
      </validation-observer>
    </b-card>
    <advertisment-section />
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BForm,
  BInputGroup,
  BCard,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AdvertismentSection from "@core/layouts/components/AdvertismentSection.vue";
import Countdown from "./Countdown.vue";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    AdvertismentSection,
    Countdown
  },
  mounted() {
    this.fetchParentTokenMetaData();
  },
  computed: {
    loading() {
      return this.$store.state.loaders.loading;
    }
  },
  data() {
    return {
      state: "",
      required,
      contract_address: ""
    };
  },
  methods: {
    linked: function(e) {
      this.$router.push({ path: "/" + e });
    },
    scanContract() {
      this.$router.push({ path: "/scan-result/" + this.contract_address });
    },
    fetchParentTokenMetaData() {
      this.$store.dispatch("FETCH_PARENT_TOKEN_DATA");
    }
  },
  directives: {
    Ripple
  }
};
</script>
<style></style>
