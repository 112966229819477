<template>
  <b-card class="pointer">
    <div class="d-flex justify-content-center mb-1 col-12 text-center">
      <h2>RugScan Token Launched!</h2>
    </div>
    <!-- <flip-countdown :deadline="countDate"></flip-countdown> -->
    <div class="d-flex justify-content-center mb-1 col-12 text-center">
      <b-col align-self="center">
        <b-img
          src="@/assets/images/logo/rugfreecoins.png"
          height="180"
          alt="RugFreeCoins"
        ></b-img>
      </b-col>
    </div>
    <div class="d-flex justify-content-center mb-1 col-12 text-center pt-1">
      <b-button @click="linked('rugscan-token')" variant="info">
        Rugscan Token
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BCol, BImg } from "bootstrap-vue";
// import FlipCountdown from "vue2-flip-countdown";
// import moment from "moment";

export default {
  components: {
    // BRow,
    BCard,
    // BBadge,
    // FlipCountdown,
    BButton,
    BCol,
    BImg,
  },
  methods: {
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
  },
  data() {
    return {
      // countDate: ""
    };
  },
  mounted() {},
  created() {
    // const vm = this;
    // var tz = moment.tz.guess();
    // let countDownDate = moment("2021-09-15 22:00:00", "YYYY-MM-DD HH:mm:ss")
    //   .tz(tz)
    //   .format("YYYY-MM-DD HH:mm:ss");
    // vm.countDate = countDownDate.toString();
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
