<template>
  <div>
    <b-row>
      <b-col lg="12 mb-1">
        <!-- <iframe
          data-aa="1701112"
          src="//acceptable.a-ads.com/1701112"
          scrolling="no"
          style="border:0px; padding:0; width:100%; height:100%; overflow:hidden"
          allowtransparency="true"
        ></iframe> -->
        <div class="d-flex justify-content-center col-12 text-center">
          <iframe
            v-if="is_mobilesize"
            data-aa="1723658"
            src="//ad.a-ads.com/1723658?size=300x250"
            style="
              width: 300px;
              height: 250px;
              border: 0px;
              padding: 0;
              overflow: hidden;
              background-color: transparent;
            "
          ></iframe>
          <iframe
            v-if="!is_mobilesize"
            data-aa="1723659"
            src="//ad.a-ads.com/1723659?size=728x90"
            style="
              width: 728px;
              height: 90px;
              border: 0px;
              padding: 0;
              overflow: hidden;
              background-color: transparent;
            "
          ></iframe>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol
  },
  setup() {}
};
</script>

<style></style>
